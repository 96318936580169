// @flow
import * as React from 'react';
import classNames from 'classnames';

import type { Style } from '../types';

type Props = {|
  children: React.Node,
  isHorizontal?: boolean,
  hasAddons?: boolean,
  addonsAlignment?: 'left' | 'centered' | 'right',
  isGrouped?: boolean,
  style?: Style,
  additionalClassName?: string,
  onClick?: (any) => any,
|};

export default function Field({
  children,
  isHorizontal,
  hasAddons,
  addonsAlignment,
  isGrouped,
  style,
  additionalClassName,
  onClick,
}: Props): React.Node {
  const className = classNames('field', additionalClassName, {
    'has-addons': hasAddons,
    'is-horizontal': isHorizontal,
    'has-addons-centered': addonsAlignment === 'centered',
    'has-addons-right': addonsAlignment === 'right',
  });

  return (
    <div
      className={className}
      style={{
        ...style,
        cursor: !!onClick ? 'pointer' : 'inherit',
      }}
      onClick={onClick}
    >
      {children}
    </div>
  );
}

Field.defaultProps = {
  isHorizontal: false,
  hasAddons: false,
  addonsAlignment: 'left',
  isGrouped: false,
};
