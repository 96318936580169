// @flow
import * as React from 'react';
import classnames from 'classnames';

type Props = {|
  children: React.Node,
  additionalClassName?: string,
|};

export default function OldLabel({
  children,
  additionalClassName,
}: Props): React.Node {
  return (
    <div className={classnames('homemade-label', additionalClassName)}>
      {children}
    </div>
  );
}
