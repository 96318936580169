// @flow
import * as React from 'react';

import InputSelectBase from './InputSelectBase';
import InputTextBase from './InputTextBase';

export type FieldInfo = {|
  type: 'select' | 'text', // missing additional types
  name: ?string,
  autocomplete: ?string,
  required: boolean,
  values: Array<any>,
  label: string,
  placeholder: string,
|};

type Props = {|
  value: any,
  onChange: (?any) => void,
  fieldInfo: FieldInfo,
|};

export default function FormField({
  value,
  onChange,
  fieldInfo,
}: Props): React.Node {
  const { type, name, autocomplete, required, values, label, placeholder } =
    fieldInfo;

  let field;

  switch (type) {
    case 'select':
      field = (
        <InputSelectBase
          name={name || autocomplete}
          autoComplete={autocomplete || name}
          label={label + ' :'}
          placeholder={placeholder || label}
          onChange={onChange}
          required={required}
          useValue={true}
          value={value}
          options={values}
        />
      );
      break;
    default:
      field = (
        <InputTextBase
          type={type || 'text'}
          name={name || autocomplete}
          autoComplete={autocomplete || name}
          placeholder={placeholder || label}
          required={required}
          value={value}
          onChange={onChange}
        />
      );
  }

  return <div className="RegularForm-field">{field}</div>;
}
