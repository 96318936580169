// @flow
import * as React from 'react';
import classNames from 'classnames';

import type { BulmaColor, BulmaShadesOfGrey } from './types';

type Size = 1 | 2 | 3 | 4 | 5 | 6 | 7;
type Transformation = 'capitalized' | 'lowercase' | 'uppercase' | 'italic';
type Weight = 'light' | 'normal' | 'medium' | 'semibold' | 'bold';
type Color =
  | BulmaColor
  | BulmaShadesOfGrey
  | 'homemade-primary'
  | 'homemade-secondary';
type Align = 'left' | 'centered' | 'right' | 'justified';
type ElementType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span' | 'p';

type Props = {|
  children: React.Node,
  isTitle?: boolean,
  isSubtitle?: boolean,
  spaced?: boolean,
  additionalClassName?: string,
  style?: Object,
  color?: Color,
  weight?: Weight,
  size?: Size,
  element?: ElementType,
  align?: Align,
  transformation?: Transformation,
|};

export default function Text({
  children,
  isTitle,
  isSubtitle,
  spaced,
  color,
  size,
  align,
  weight,
  transformation,
  element: ElementTag = 'span',
  additionalClassName,
  style,
}: Props): React.Node {
  const sizeClassName = !!size ? `is-size-${size}` : '';
  const alignmentClassName = !!align ? `has-text-${align}` : '';
  const weightClassName = !!weight ? `has-text-weight-${weight}` : '';
  const colorClassName = !!color ? `has-text-${color}` : '';
  const transformationClassName = !!transformation
    ? `is-${transformation}`
    : '';

  const className = classNames(
    sizeClassName,
    alignmentClassName,
    weightClassName,
    colorClassName,
    transformationClassName,
    additionalClassName,
    {
      title: !!isTitle,
      subtitle: !!isSubtitle,
      'is-spaced': spaced,
    }
  );

  return (
    <ElementTag className={className} style={style}>
      {children}
    </ElementTag>
  );
}

Text.defaultProps = {
  element: 'span',
};
