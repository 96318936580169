// @flow
import * as React from 'react';
import Tooltip from 'rc-tooltip';
import classnames from 'classnames';

import Icon from '../Icon';

type Props = {|
  content: string,
  additionalClassName?: string,
|};

export default function QuestionMarkTooltip({
  content,
  additionalClassName,
}: Props): React.Node {
  const className = classnames('question-mark-tooltip', additionalClassName);

  return (
    <span className={className}>
      <Tooltip placement="top" trigger={['hover']} overlay={content}>
        <span className="trigger">
          <Icon name="question-circle" />
        </span>
      </Tooltip>
    </span>
  );
}
