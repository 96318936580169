import { useRecoilState } from 'recoil';
import { startCase } from 'lodash';

import { getDisplayName } from '@/helpers/react';

export default function withRecoilState(name, recoilState) {
  return function (Component) {
    function WithRecoilComponent(props) {
      const [state, setState] = useRecoilState(recoilState);

      return (
        <Component
          {...props}
          {...{
            [name]: state,
            [`set${startCase(name).split(' ').join('')}`]: setState,
          }}
        />
      );
    }

    WithRecoilComponent.displayName = `withRecoilState(${getDisplayName(
      Component
    )})`;

    return WithRecoilComponent;
  };
}
