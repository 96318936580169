// @flow
import * as React from 'react';

import type { Site } from '@/types/site';

import { SiteContext } from '@/globals/contexts';
import { getDisplayName } from '@/helpers/react';
import invariant from '@/helpers/invariant';

const withSite = function <T>(
  Component: React.ComponentType<{| ...T, site: Site |}>
): React.ComponentType<T> {
  const WithSiteComponent = React.forwardRef((props: T, ref) => {
    return (
      <SiteContext.Consumer>
        {(siteContext) => {
          invariant(siteContext, 'SiteContext should always be defined');
          return <Component {...props} site={siteContext.site} ref={ref} />;
        }}
      </SiteContext.Consumer>
    );
  });

  withSite.displayName = `withSite(${getDisplayName(Component)})`;

  return WithSiteComponent;
};

export default withSite;
