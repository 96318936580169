import _ from 'lodash';
import React from 'react';

import { APIContext } from '@/globals/contexts';

export default function RichTextContent(props) {
  const { getImageUrl } = React.useContext(APIContext);
  let html = props.html;

  if (html && html.contentState) {
    // workaround server bug
    console.error('raw rich text content', html);
    html = html.html;
  }

  if (html && html.hasOwnProperty('html')) {
    // workaround server bug 2
    html = html.html;
  }

  if (!html || html === '<p><br></p>') return null;

  const patchedHtml = html.replace(
    /(<img[^>]*)\bdata-url="([^"]+)"/g,
    (match, start, url) => {
      return start.replace(/src="[^"]*"/, '') + ` src="${getImageUrl(url)}"`;
    }
  );

  const Tag = props.tagName || 'div';

  return (
    <Tag
      {..._.omit(props, ['html', 'tagName'])}
      className={(props.className || '') + ' RichTextContent'}
      dangerouslySetInnerHTML={{ __html: patchedHtml }}
    />
  );
}
