// @flow
import ApiClient from '@/utils/ApiClient';

export type Unsubscribe = () => void;
export type Callback = (any) => void;
export type AddUnsub = (Unsubscribe) => void;

export type CreateLoadCallbacks = (
  callback: () => void,
  onError: () => void
) => void;

export type BindObject = (
  ressource: string,
  id: string,
  params: ?{},
  callback: Callback,
  loadNow?: boolean
) => void;

export type BindCollection = (
  ressource: string,
  params: ?{},
  callback: Callback,
  loadNow?: boolean
) => void;

export default class DataBinder {
  _unsubscribeFunctions: Array<Unsubscribe> = [];

  _addUnsub: AddUnsub = (unsubFunc) => {
    this._unsubscribeFunctions.push(unsubFunc);
  };

  bindObject: BindObject = (resource, id, params, callback, loadNow = true) => {
    this._addUnsub(
      ApiClient.bindObject(resource, id, params, callback, null, null, loadNow)
    );
  };

  bindCollection: BindCollection = (
    resource,
    params,
    callback,
    loadNow = true
  ) => {
    this._addUnsub(
      ApiClient.bindCollection(resource, params, callback, null, null, loadNow)
    );
  };
}
