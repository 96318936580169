// @flow
import * as React from 'react';

import type { SiteConfigOptions } from '@/types/site';

import { SiteContext } from '@/globals/contexts';
import { getDisplayName } from '@/helpers/react';
import invariant from '@/helpers/invariant';

const withSiteOptions = function <T>(
  Component: React.ComponentType<{| ...T, siteOptions: SiteConfigOptions |}>
): React.ComponentType<T> {
  const WithSiteOptionsComponent = React.forwardRef((props: T, ref) => {
    return (
      <SiteContext.Consumer>
        {(siteContext) => {
          invariant(siteContext, 'SiteContext should always be defined');
          return (
            <Component
              {...props}
              siteOptions={siteContext.siteConfig.options}
              ref={ref}
            />
          );
        }}
      </SiteContext.Consumer>
    );
  });

  withSiteOptions.displayName = `withSiteOptions(${getDisplayName(Component)})`;

  return WithSiteOptionsComponent;
};

export default withSiteOptions;
