// @flow
import * as React from 'react';
import classnames from 'classnames';

type Props = {|
  children: React.Node,
  required?: boolean,
  additionalClassName?: string,
  labelFor?: string,
  style?: Object,
|};

export default function Label({
  children,
  required,
  additionalClassName,
  labelFor,
  style,
}: Props): React.Node {
  if (!children) return null;

  return (
    <label
      className={classnames('label', additionalClassName)}
      for={labelFor}
      style={style}
    >
      {children}
      {required ? '*' : ''}
    </label>
  );
}
