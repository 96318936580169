// @flow

import * as React from 'react';
import _ from 'lodash';
import classnames from 'classnames';

export type RadioOption = {|
  value: any,
  label: string,
|};

type Props = {|
  value: any,
  onChange: (any) => void,
  radios: Array<RadioOption>,
  additionalClassName?: string,
|};

export default function InputRadioBase({
  value,
  radios,
  onChange,
  additionalClassName,
}: Props): React.Node {
  const componentBaseClass = 'InputRadioBase';

  return (
    <div className={classnames(componentBaseClass, additionalClassName)}>
      {radios.map((radio, i) => (
        <span
          className={classnames(`${componentBaseClass}-radio-element`, {
            [`${componentBaseClass}-radio-element--selected`]: _.isEqual(
              value,
              radio.value
            ),
          })}
          key={radio.value}
          onClick={(e) => onChange(radio.value)}
        >
          {radio.label && (
            <span className={`${componentBaseClass}-radio-label`}>
              {radio.label}
            </span>
          )}
        </span>
      ))}
    </div>
  );
}
