// @flow

import * as React from 'react';
import _ from 'lodash';
import classNames from 'classnames';

import type { BulmaColor, BulmaSize, Style } from './types';

type StyleProps = {|
  color?: BulmaColor | 'light',
  size?: BulmaSize | 'tiny',

  isFullWidth?: boolean,
  isRounded?: boolean,
  isOutlined?: boolean,
  isInverted?: boolean,
  isText?: boolean,
  isExpanded?: boolean,
|};

type Props = {|
  ...StyleProps,

  isLoading?: boolean,
  isHovered?: boolean,
  isFocused?: boolean,
  isActive?: boolean,
  isStatic?: boolean,

  element?: 'a' | 'span' | 'button',
  type?: 'submit' | 'button',
  href?: string,
  className?: string,
  isActive?: boolean,
  onClick?: () => any,
  title?: string,
  children: React.Node,
  disabled?: boolean,
  additionalClassName?: string,
  style?: Style,
  id?: string,
  target?: string,
  iconButton?: boolean,
|};

export default function Button(props: Props): React.Node {
  const {
    color,
    element,
    type,
    href,
    className,
    additionalClassName,
    disabled,
    isActive,
    onClick,
    title,
    children,
    target,
    ...otherProps
  } = props;
  const Element = element || (href ? 'a' : 'button');
  const buttonType = Element === 'button' ? type || 'button' : undefined;

  return (
    <Element
      {..._.omit(otherProps, [
        'isFullWidth',
        'isText',
        'isOutlined',
        'iconButton',
      ])}
      active={null} // omit active
      className={getClassName(props)}
      onClick={onClick}
      href={href}
      title={title || undefined}
      type={buttonType}
      disabled={disabled}
      target={target}
    >
      {children}
    </Element>
  );
}

const getClassName = (props: Props) => {
  const {
    size,
    color,

    isHovered,
    isFocused,
    isActive,
    isStatic,
    disabled,
    isLoading,

    iconButton,
    isFullWidth,
    isOutlined,
    isRounded,
    isInverted,
    isExpanded,
    isText,
    additionalClassName,
  } = props;

  const colorClassName = classNames(color && `is-${color}`);

  const stateClassName = classNames({
    'is-hovered': !!isHovered,
    'is-focused': !!isFocused,
    'is-active': !!isActive,
    'is-loading': !!isLoading,
    'is-static': !!isStatic,
    'is-disabled': !!disabled,
  });

  const sizeClassName = classNames(size && `is-${size}`);

  return classNames(
    'homemade-button',
    additionalClassName,
    colorClassName,
    stateClassName,
    sizeClassName,
    {
      'is-icon': !!iconButton,
      'is-outlined': !!isOutlined,
      'is-inverted': !!isInverted,
      'is-rounded': !!isRounded,
      'is-text': !!isText,
      'is-expanded': !!isExpanded,
      'is-fullwidth': !!isFullWidth,
    }
  );
};
