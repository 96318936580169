// @flow
import * as React from 'react';
import classnames from 'classnames';

import type { Style } from '@/components/new/types';

type Props = {|
  children: React.Node,
  additionalClassName?: string,
  style?: Style,
  id?: string,
|};

export default function OldField({
  children,
  additionalClassName,
  style,
  id,
}: Props): React.Node {
  return (
    <div
      className={classnames('RegularForm-field', additionalClassName)}
      style={style}
      id={id}
    >
      {children}
    </div>
  );
}
