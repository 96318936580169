// File upload based on https://github.com/SoAanyip/React-FileUpload

import _ from 'lodash';
import React from 'react';
import Dropzone from 'react-dropzone';
import request from 'superagent';

import { InstanceStore, UserStore, UserActions } from '@/reflux';
import { APIContext } from '@/globals/contexts';
import { withContext } from '@/utils/HOC';

import ProgressBar from '../ProgressBar';
import ComponentBase from '@/PUFComponents/ComponentBase';

class InputFileUpload extends ComponentBase {
  stores = [InstanceStore, UserStore];

  constructor(props) {
    super(props);

    this.state = {
      files: [],
      error: null,
      hasFocus: false,
    };
  }

  didMount() {
    this.observeProp('value', (value) =>
      this.setState({
        files:
          (value && [
            {
              name: _.startsWith(value, 'data:')
                ? '(inline image data)'
                : value,
              value,
              url: value,
            },
          ]) ||
          [],
      })
    );

    UserActions.set.listen((user) => {
      if (
        this.props.autoSendWithUser &&
        user &&
        !_.isEmpty(this.state.files) &&
        !this.state.sent
      ) {
        this._send(this.state.files);
      }
    });
  }

  handleFocus(e, hasFocus) {
    this.setState({ hasFocus: hasFocus });
  }

  onDrop(files) {
    this.setState({ files, error: null, sent: false });
    if (
      !(
        this.props.manualSend ||
        (this.props.autoSendWithUser && !this.state.user)
      )
    ) {
      this._send(files);
    }

    this.props.onDrop && this.props.onDrop(files);
  }

  // public function
  sendFiles() {
    this._send(this.state.files);
  }

  _send(files) {
    const {
      apiContext: { userContributionUploadUrl },
    } = this.props;

    const req = request.post(userContributionUploadUrl).withCredentials();
    req.timeout(3600 * 1000);

    this.setState({ progress: 0, progressSize: 0, uploading: true });
    req.on('progress', (e) =>
      this.setState({ progress: e.percent, progressSize: e.loaded })
    );

    files.forEach((file) => {
      req.attach('file', file);
    });

    // TODO handle multiple upload
    req.end((error, res) => {
      this.setState({ progress: 0, progressSize: 0, uploading: false });
      if (error) {
        console.error('upload error', error);
        this.setState({ error: error.toString() });
        this.props.onError && this.props.onError(error);
      } else {
        console.debug('end of upload', res);
        files[0].url = res.body.result;

        this.setState({ files, error: null, sent: true });

        // TODO multiple files
        this.props.onChange && this.props.onChange(res.body.result);
        this.props.onSent && this.props.onSent(res.body.result);
      }
    });
  }

  render() {
    let files = this.state.files;
    const {
      apiContext: { userContributionUrl },
    } = this.props;

    let error = this.state.error;
    let disabled = this.props.disabled || false;

    let multiple = this.props.multiple || false;

    let formElementClass = 'InputFileUpload';
    formElementClass += disabled ? ' disabled' : '';
    formElementClass += error ? ' error' : '';
    formElementClass += this.state.hasFocus ? ' focus' : '';

    let accept =
      this.props.accept === null
        ? undefined
        : this.props.accept
        ? this.props.accept
        : [
            'image/jpeg',
            'image/png',
            'image/gif',
            'application/pdf',
            'application/msword',
          ];

    return (
      <div className={formElementClass}>
        <Dropzone
          onDrop={this.onDrop.bind(this)}
          multiple={multiple}
          accept={accept}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} className="input-dropzone-component-inner">
              <input {...getInputProps()} multiple />
              <p>📁 Cliquez ou déposez votre fichier ici</p>

              {this.state.uploading && (
                <div className="center-content">Envoi en cours...</div>
              )}

              <div className="dropped-files-list dropped-files-list-to-upload">
                {files.map(
                  (f) =>
                    !f.url && (
                      <div
                        className="FormElement-dropped-file-item"
                        key={f.name}
                      >
                        {f.preview && (
                          <div className="FormElement-dropped-file-preview FormElement-dropped-file-preview--uploading">
                            <img src={f.preview} alt="uploaded-file" />
                          </div>
                        )}
                        <span>{f.name}</span>
                      </div>
                    )
                )}
              </div>

              {this.state.uploading && (
                <ProgressBar progress={this.state.progress}>
                  {~~this.state.progress} %
                </ProgressBar>
              )}
            </div>
          )}
        </Dropzone>

        <div className="dropped-files-list dropped-files-list-uploaded">
          {this.state.files.map(
            (f) =>
              f.url && (
                <div className="FormElement-dropped-file-item" key={f.name}>
                  ✅{' '}
                  <a
                    download
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${userContributionUrl}/show/${encodeURIComponent(
                      f.name
                    )}`}
                  >
                    {f.name}
                  </a>
                </div>
              )
          )}
        </div>

        {error && <div className="error">{error}</div>}
      </div>
    );
  }
}

export default withContext(APIContext, 'apiContext', InputFileUpload);
