// @flow
import * as React from 'react';
import classNames from 'classnames';

import type { Style } from '../types';

import Icon from '../Icon';

type Type = 'danger' | 'success' | 'warning' | 'info';
type Goal = [boolean, string];
type Props = {|
  type: Type,
  goals?: Array<Goal>,
  children: React.Node,
  style?: Style,
  additionalClassName?: string,
  size?: 'small' | 'normal',
  isOutlined?: boolean,
|};

export default function InformationBubble({
  type,
  goals,
  children,
  size,
  isOutlined,
  style,
  additionalClassName,
}: Props): React.Node {
  return (
    <div
      className={classNames(
        'information-bubble',
        additionalClassName || '',
        !!type ? `is-${type}` : 'is-warning',
        !!size ? `is-${size}` : undefined,
        {
          'is-outlined': !!isOutlined,
        }
      )}
      style={style}
    >
      <div className="icon-part">
        <Icon name="lightbulb" type="regular" />
      </div>
      <div className="content-part">
        {children}
        {!!goals && goals.length > 0 && (
          <ul>
            {goals.map(([done, goal]) => (
              <li className={classNames({ done })} key={goal}>
                {done ? (
                  <Icon name="check" style={{ marginRight: 10 }} />
                ) : (
                  <Icon name="times" style={{ marginRight: 10 }} />
                )}
                <span>{goal}</span>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}
