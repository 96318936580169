// @flow

import * as React from 'react';
import classnames from 'classnames';

export type Option = {|
  value: any,
  label: string,
  disabled?: boolean,
|};

type Props = {|
  value: any,
  label?: string,
  required?: boolean,
  multiple?: boolean,
  options: Array<Option>,
  placeholder: string,
  useValue: boolean,
  name?: ?string,
  autoComplete?: ?string,
  additionalClassName?: string,
  onChange: (any) => void,
  disabled?: boolean,
|};

export default function InputSelectBase({
  value,
  label,
  required,
  multiple,
  options,
  placeholder,
  useValue,
  name,
  autoComplete,
  additionalClassName,
  onChange: onChangeProp,
  disabled,
}: Props): React.Node {
  // onChangeEvent = (e) => {
  //   console.debug('drop down new value', JSON.parse(e.target.value));
  //   this.props.onChange && this.props.onChange(JSON.parse(e.target.value));
  //   e.persist();
  // };

  if (label && required) label += '*';

  let componentBaseClass = 'InputSelectBase';
  let componentMainClass = componentBaseClass;

  const onChange = React.useCallback(
    (e: SyntheticInputEvent<*>) => {
      onChangeProp && onChangeProp(JSON.parse(e.target.value));
      e.persist();
    },
    [onChangeProp]
  );

  return (
    <div className={classnames(componentMainClass, additionalClassName)}>
      {label && (
        <div className={`${componentBaseClass}-label`}>{`${label}${
          required ? '*' : ''
        }`}</div>
      )}

      <div className={`${componentBaseClass}-select`}>
        <select
          disabled={disabled}
          onChange={onChange}
          defaultValue={!useValue ? JSON.stringify(value || null) : undefined}
          value={useValue ? JSON.stringify(value || null) : undefined} // useValue false = retrocompat
          multiple={multiple}
          name={name}
          autoComplete={autoComplete}
          className={placeholder && !value ? 'placeholder-selected' : ''} // if we use null there is a bug, the value does not change
        >
          {(!required || !value) && ( // warning: prevents values resolving fo false!
            <option
              className={componentBaseClass + '-placeholder'}
              value="null"
              disabled={required}
            >
              {placeholder || ''}
            </option>
          )}

          {options &&
            options.map &&
            options.map((option, i) => (
              <option
                key={i}
                value={JSON.stringify(option.value)}
                disabled={option.disabled}
              >
                {option.label}
              </option>
            ))}
        </select>
      </div>
    </div>
  );
}
