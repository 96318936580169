// @flow
import * as React from 'react';
import classNames from 'classnames';

import type { Style } from '../types';

type Props = {|
  children: React.Node,
  hasIconsLeft?: boolean,
  hasIconsRight?: boolean,
  isLoading?: boolean,
  isExpanded?: boolean,
  style?: Style,
|};

export default function Control({
  children,
  hasIconsLeft,
  hasIconsRight,
  isExpanded,
  isLoading,
  style,
}: Props): React.Node {
  const className = classNames('control', {
    'has-icons-right': hasIconsRight,
    'has-icons-left': hasIconsLeft,
    'is-expanded': isExpanded,
    'is-loading': isLoading,
  });

  return (
    <div className={className} style={style}>
      {children}
    </div>
  );
}

Control.defaultProps = {
  hasIconsLeft: false,
  hasIconsRight: false,
  isExpanded: false,
};
