// @flow
import * as React from 'react';
import classnames from 'classnames';

import type { Style } from '../types';

type Props = {|
  disabled?: boolean,
  active?: boolean,
  isSubmit?: boolean,
  onClick?: (SyntheticEvent<*>) => any,
  children: React.Node,
  title?: string,
  id?: string,
  style?: Style,
  additionalClassName?: string,
  isText?: boolean,
|};

export default function Button({
  disabled,
  active,
  isSubmit,
  onClick,
  children,
  title,
  id,
  style,
  additionalClassName,
  isText,
}: Props): React.Node {
  const className = classnames('RegularForm-submit', additionalClassName, {
    'RegularForm-submit-disabled': disabled,
    'RegularForm-submit-active': active,
    'is-text': isText,
  });

  return isSubmit ? (
    <input
      type="submit"
      disabled={disabled}
      className={className}
      onClick={(e) => {
        e.preventDefault();
        !disabled && onClick && onClick(e);
      }}
      value={children || 'Valider'}
      id={id}
      style={style}
    />
  ) : (
    <span
      id={id}
      className={className}
      onClick={(!disabled && onClick) || undefined}
      title={title || children || ''}
      style={style}
    >
      {children || 'Valider'}
    </span>
  );
}
