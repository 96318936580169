/**

 src = absolute/relative image url, ex.

 @see https://github.com/lencioni/SLIR#using

 w   Maximum width
 h   Maximum height
 c   Crop ratio
 b   Background fill color (hex)
 q   Quality
 p   Progressive

 other params are <img> tag attributes

 */

import _ from 'lodash';
import React from 'react';
import { getFeatureFlags, featureEnabled } from '@/helpers/models/instance';
import { useSite } from '@/hooks';
import { APIContext } from '@/globals/contexts';

import { Image } from '@/components/new';

// TODO param https://developer.mozilla.org/en-US/docs/Web/CSS/object-fit also ?

var DYNIMG_PARAMS = { w: 1, h: 1, algo: 1, force: 1, resetCache: 1 };

// https://developers.google.com/speed/webp/faq#how_can_i_detect_browser_support_for_webp
// check_webp_feature:
//   'feature' can be one of 'lossy', 'lossless', 'alpha' or 'animation'.
//   'callback(feature, result)' will be passed back the detection result (in an asynchronous way!)
/*
function checkWebp(feature, callback) {
    var kTestImages = {
        lossy    : "UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA",
        lossless : "UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==",
        alpha    : "UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==",
        animation: "UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA"
    };
    var img         = new Image();
    img.onload      = function () {
        var result = (img.width > 0) && (img.height > 0);
        callback(feature, result);
    };
    img.onerror     = function () {
        callback(feature, false);
    };
    img.src         = "data:image/webp;base64," + kTestImages[feature];
}
*/

function LegacyDynImg(props) {
  const { getImageUrl } = React.useContext(APIContext);
  let { src } = props;

  if (src) {
    let params = {},
      imgAttr = {};
    _.forEach(props, (v, p) => {
      if (p in DYNIMG_PARAMS) {
        params[p] = v;
      }
      // TODO this is stupid, use an 'imgAttr' prop instead
      else if (p !== 'src' && p !== 'assumeNoAlpha') {
        imgAttr[p] = v;
      }
    });

    imgAttr.alt = imgAttr.alt || imgAttr.title || '';

    let ext = src.split('.').pop().toLowerCase();

    let { w, h } = props;
    if (ext === 'svg' && (!w || !h)) {
      // SVG & no crop ? use original SVG and set w or h in CSS
      return (
        <img
          src={getImageUrl(src)}
          style={{
            width: w ? w + 'px' : 'auto',
            height: h ? h + 'px' : 'auto',
          }}
          alt={imgAttr.title}
          {...imgAttr}
          loading="lazy"
        />
      );
    } // otherwise use crop: server will convert SVG to PNG

    let url = getImageUrl(src);
    //let urlWebp = dynImgUrl(src, params, "webp");

    // https://www.html5rocks.com/en/tutorials/responsive/picture-element/#toc-file-type

    return <img src={url} alt={imgAttr.title} {...imgAttr} loading="lazy" />;
  }

  return <img alt="empty" loading="lazy" />;
}

export default function DynImg(props) {
  const site = useSite();
  const featureFlags = getFeatureFlags(site);
  const isCloudflareImageOptimizationEnabled = featureEnabled(
    'cloudflareImageOptimization',
    featureFlags
  );

  if (!isCloudflareImageOptimizationEnabled) return <LegacyDynImg {...props} />;
  return <Image {...props} width={props.w} />;
}
