// @flow

import * as React from 'react';
import cn from 'classnames';

type Props = {|
  position: string,
  additionalClassName?: string,
  innerRef: any,
  duration?: number,
  onClose?: () => void,
  open: boolean,
  hideable?: boolean,
  buttonText?: string,
  contentClassName?: string,
  children: React.Node,
|};

class InfoBannerBase extends React.Component<Props> {
  _element: any = null;

  closeBanner() {
    if (this._element) {
      this._element.classList.add('InfoBanner--closing');

      var self = this;
      setTimeout(function () {
        self.props.onClose && self.props.onClose();
      }, self.props.duration || 1000);
    } else {
      this.props.onClose && this.props.onClose();
    }
  }

  render() {
    const {
      additionalClassName,
      position,
      innerRef,
      open,
      contentClassName,
      children,
      buttonText,
      hideable,
    } = this.props;

    return (
      <div
        className={cn(
          'InfoBanner InfoBanner--' + (position || 'bottom'),
          additionalClassName
        )}
        ref={innerRef}
      >
        <div className="InfoBanner-wrapper">
          {open && (
            <div className="siteWrapper">
              <div
                className={
                  contentClassName
                    ? 'InfoBanner-inner ' + contentClassName
                    : 'InfoBanner-inner'
                }
              >
                <div className="InfoBanner-content">
                  <div className="InfoBanner-content-layout">{children}</div>

                  {hideable !== false && (
                    <div className="InfoBanner-close">
                      <button
                        className="InfoBanner-close-button"
                        onClick={this.closeBanner}
                      >
                        {buttonText || 'OK'}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const InfoBanner = (React.forwardRef((props: Props, ref) => (
  <InfoBannerBase innerRef={ref} {...props} />
)): React.ComponentType<Props>);

export default InfoBanner;
