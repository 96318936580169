// @flow
import * as React from 'react';
import moment from 'moment';
import _ from 'lodash';

type Props = {|
  ts: number,
  format: string, // https://momentjs.com/docs/#/displaying/
  className?: string,
|};

export default function Date({ ts, className, format }: Props): React.Node {
  return !_.isNil(ts) ? (
    <span className={className}>{moment.unix(ts).format(format)}</span>
  ) : null;
}

Date.defaultProps = {
  format: 'LLL',
};
