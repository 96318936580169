// @flow
import * as React from 'react';

import type { LinksContext as LinksContextType } from '@/types/site';

import { LinksContext } from '@/pageComponents/contexts';
import { getDisplayName } from '@/helpers/react';
import invariant from '@/helpers/invariant';

const withLinks = function <T>(
  Component: React.ComponentType<{| ...T, links: LinksContextType |}>
): React.ComponentType<T> {
  function WithLinksComponent(props: T) {
    const linksContext = React.useContext(LinksContext);

    invariant(!!linksContext, 'LinksContext should be defined');

    return <Component {...props} links={linksContext} />;
  }

  WithLinksComponent.displayName = `withLinks(${getDisplayName(Component)})`;

  return WithLinksComponent;
};

export default withLinks;
