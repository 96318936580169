import * as React from 'react';
import classnames from 'classnames';

export default function Textarea({
  additionalClassName,
  value,
  onChange,
  placeholder,
  name,
}) {
  return (
    <textarea
      name={name}
      className={classnames('homemade-textarea', additionalClassName)}
      placeholder={placeholder}
      value={value}
      onChange={(e) => onChange(e.target.value)}
    />
  );
}
