// @flow
import * as React from 'react';

type Props = {|
  progress: number,
  children: React.Node,
|};

export default function ProgressBar({ progress, children }: Props): React.Node {
  return (
    <div className="ProgressBar">
      <div className="ProgressBar-bar" style={{ width: progress + '%' }} />
      <div className="ProgressBar-size">{children}</div>
    </div>
  );
}
