import * as React from 'react';
import ReactDatePicker from 'react-datepicker';

import classnames from 'classnames';

const TIME_FORMAT = 'HH:mm';
const DATE_FORMAT = 'dd/MM/yyyy';
const FULL_FORMAT = 'dd/MM/yyyy HH:mm:ss';

export default function Datepicker({
  value,
  onChange,
  additionalClassName,
  showFullFormat,
  showTimeSelect,
  showTimeSelectOnly,
  disabled,
  minDate,
}) {
  const dateFormat = showTimeSelectOnly
    ? TIME_FORMAT
    : showFullFormat
    ? FULL_FORMAT
    : DATE_FORMAT;

  return (
    <div className={classnames('InputTextBase', additionalClassName)}>
      <ReactDatePicker
        className="InputTextBase-text-input"
        placeholderText={'Sélectionnez une date'}
        selected={value}
        onChange={onChange}
        dateFormat={dateFormat}
        showTimeSelect={showTimeSelect}
        disabled={disabled}
        minDate={minDate}
      />
    </div>
  );
}
