// @flow

import * as React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';

import { useOnKeyDown } from '@/hooks';

type Props = {|
  title?: string,
  titleCentered?: boolean,
  onClose: () => void,
  size?: 'full' | 'two-third' | 'half' | 'one-third',
  additionalClassName?: string,
  contentClassName?: string,
  children: React.Node,
  innerStyle?: Object,
  target?: string,
|};

export default function Modal({
  title,
  titleCentered,
  onClose,
  size = 'full',
  contentClassName,
  children,
  additionalClassName,
  innerStyle,
  target,
}: Props): React.Node {
  useOnKeyDown(
    {
      onEscapeKeyDown: onClose,
    },
    [onClose]
  );

  // usePreventBodyScrolling();

  if (typeof document === 'undefined') return null;

  return ReactDOM.createPortal(
    <div
      className={classNames('Modal', additionalClassName, {
        'relative-modal': !!target,
      })}
    >
      <div className="Modal-wrapper" onClick={onClose || undefined}>
        <div className={classNames('Modal-inner', `Modal-inner--${size}`)}>
          <div
            className={classNames('Modal-inner-wrapper', contentClassName)}
            onClick={(e) => e.stopPropagation()}
            style={innerStyle}
          >
            {onClose && (
              <button className="Modal-close-modal" onClick={onClose}>
                ✖
              </button>
            )}

            {title && (
              <div className="Modal-header">
                <h2
                  className={classNames('Modal-content-title', {
                    centered: !!titleCentered,
                  })}
                >
                  {title}
                </h2>
              </div>
            )}

            <div className="Modal-content">{children}</div>
          </div>
        </div>
      </div>
    </div>,
    document.getElementById(target || 'modal-root')
  );
}
