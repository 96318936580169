// @flow
import * as React from 'react';

import type { InstanceName, FeatureFlags } from '@/types/models';

import { SiteContext } from '@/globals/contexts';
import { getDisplayName } from '@/helpers/react';
import { getFeatureFlags } from '@/helpers/models/instance';
import invariant from '@/helpers/invariant';

export type WithInstanceProps = {|
  instanceName: InstanceName,
  featureFlags: $Shape<FeatureFlags>,
  draftMode: boolean,
|};

export default function withInstance<P>(
  Component: React.ComponentType<{| ...P, ...WithInstanceProps |}>
): React.ComponentType<P> {
  function WithInstanceNameComponent(props: P) {
    return (
      <SiteContext.Consumer>
        {(siteContext) => {
          invariant(siteContext, 'Site context should be defined');
          const featureFlags = getFeatureFlags(siteContext.site);

          return (
            <Component
              {...props}
              instanceName={siteContext.instanceName}
              featureFlags={featureFlags}
              draftMode={siteContext.draftMode}
            />
          );
        }}
      </SiteContext.Consumer>
    );
  }

  WithInstanceNameComponent.displayName = `withInstance(${getDisplayName(
    Component
  )})`;

  return WithInstanceNameComponent;
}
