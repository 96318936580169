import * as React from 'react';
import _ from 'lodash';
import { SiteContext, PageContext, PaywallContext } from '@/globals/contexts';

const contexts = {
  siteContext: SiteContext,
  pageContext: PageContext,
  paywallContext: PaywallContext,
};

const withContext = (Context, contextProp, Component) =>
  React.forwardRef(({ ...props }, ref) => {
    return (
      <Context.Consumer>
        {(context) => (
          <Component {...props} {...{ [contextProp]: context }} ref={ref} />
        )}
      </Context.Consumer>
    );
  });

export default withContext;

export const withContexts = (contextNameParam, Component) => (props) => {
  const contextNames = Array.isArray(contextNameParam)
    ? contextNameParam
    : [contextNameParam];

  let WrappedComponent = Component;
  contextNames.forEach((name) => {
    const Context = contexts[name];
    WrappedComponent = withContext(Context, name, WrappedComponent);
  });

  return <WrappedComponent {...props} />;
};

export const withContextProp =
  (Context, propName, propPath, Component) => (props) =>
    (
      <Context.Consumer>
        {(context) => (
          <Component {...props} {...{ [propName]: _.get(context, propPath) }} />
        )}
      </Context.Consumer>
    );
