// @flow

import _ from 'lodash';
import * as React from 'react';
import classnames from 'classnames';

import type { Style } from '@/components/new/types';

type Props<T> = {|
  value: T,
  label?: string,
  required?: boolean,
  type: string,
  placeholder?: string,
  autoComplete?: ?string,
  name?: ?string,
  additionalClassName?: string,
  suffix?: string,
  onChange?: (T) => void,
  autoFocus?: boolean,
  min?: string,
  max?: string,
  disabled?: boolean,
  style?: Style,
|};

export default function InputTextBase<T>({
  value,
  label,
  required,
  type,
  placeholder,
  autoComplete,
  name,
  additionalClassName,
  suffix,
  onChange,
  disabled,
  style,
  ...restOfProps
}: Props<T>): React.Node {
  return (
    <div
      className={classnames('InputTextBase', additionalClassName, {
        'has-suffix': !!suffix,
      })}
      style={style}
    >
      {label && (
        <div className="InputTextBase-label">{`${label}${
          required ? '*' : ''
        }`}</div>
      )}

      <input
        {...restOfProps}
        disabled={disabled}
        type={type}
        placeholder={placeholder || ''}
        autoComplete={autoComplete} // list: https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#inappropriate-for-the-control
        name={name || autoComplete || ''}
        className="InputTextBase-text-input"
        value={!_.isNil(value) ? value : ''}
        onChange={(e) => !!onChange && onChange(e.target.value)}
      />

      {suffix && <span className="InputTextBase-suffix">{suffix}</span>}
    </div>
  );
}
