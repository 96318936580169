// @flow
import * as React from 'react';
import classnames from 'classnames';

import { QuestionMarkTooltip } from '@/components/new';

type Props = {|
  onChange?: (boolean) => void,
  label: React.Node,
  value: boolean,
  clickableLabel?: boolean,
  disabled?: boolean,
  disabledReason?: string,
  additionalClassName?: string,
|};

export default class InputCheckboxBase extends React.Component<Props> {
  onChangeValue(e: SyntheticInputEvent<*>, newValue: boolean) {
    e.preventDefault();
    const { onChange } = this.props;
    !!onChange && onChange(newValue);
  }

  render(): React.Node {
    const {
      label,
      value,
      clickableLabel,
      disabled,
      disabledReason,
      additionalClassName,
    } = this.props;

    const className = classnames('InputCheckboxBase', {
      'InputCheckboxBase--clickable-label': !!clickableLabel,
      disabled,
    });
    const checkboxClassName = classnames('InputCheckboxBase-checkbox', {
      'InputCheckboxBase-checkbox--checked': !!value,
    });

    return (
      <div className={`${className} ${additionalClassName || ''}`}>
        <button
          className={checkboxClassName}
          onClick={(e) => this.onChangeValue(e, !value)}
          disabled={disabled}
          type="button"
        >
          <span className="InputCheckboxBase-checkbox-button"></span>
        </button>

        <span
          className="InputCheckboxBase-label"
          onClick={(e) => clickableLabel && this.onChangeValue(e, !value)}
        >
          {label}
        </span>

        {disabled && disabledReason && (
          <QuestionMarkTooltip
            content={disabledReason}
            additionalClassName="checkbox-disabled-reason"
          />
        )}
      </div>
    );
  }
}
