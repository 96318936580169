// @flow
import * as React from 'react';

import type { BindObject } from '@/helpers/data';

import { getDisplayName } from '@/helpers/react';
import DataBinder from '@/helpers/data';

export type DataBindingProps = {|
  bindObject: BindObject,
|};

export default function withDataBinding<P>(
  Component: React.ComponentType<{| ...P, ...DataBindingProps |}>
): React.ComponentType<P> {
  class WithDataBinding extends React.Component<P> {
    dataBinder: DataBinder = new DataBinder();

    componentWillUnmount = () => {
      if (this.dataBinder._unsubscribeFunctions)
        this.dataBinder._unsubscribeFunctions.forEach((unsubscribe) =>
          unsubscribe()
        );
      this.dataBinder._unsubscribeFunctions = [];
    };

    render() {
      return (
        <Component {...this.props} bindObject={this.dataBinder.bindObject} />
      );
    }
  }

  WithDataBinding.displayName = `WithDataBinding(${getDisplayName(Component)})`;

  // $FlowIgnore
  return WithDataBinding;
}
