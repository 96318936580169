import _ from 'lodash';
import * as React from 'react';

// vieux code nul corrigé à la va vite
// TODO virer ça et remplacer par un "bon" composant slider comme celui de FL
// garder seulement le style

class ValueSlider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // this is actually percent and not value:
      value: this.getPercentFromValue(props.minValue),
      textValue: props.minValue,
    };
  }

  componentDidMount() {
    this.mouseDown = false;

    let mouseMoveEvent = window.addEventListener('mousemove', (e) => {
      if (e.buttons && this.mouseDown) {
        this.handleSlider(e);
      }
    });

    let touchMoveEvent = window.addEventListener('touchmove', (e) => {
      if (this.mouseDown) {
        this.handleSlider(e);
      }
    });

    let mouseUpEvent = window.addEventListener('mouseup', (e) => {
      if (this.mouseDown) {
        window.removeEventListener('mousemove', mouseMoveEvent);
        window.removeEventListener('mouseup', mouseUpEvent);
        this.mouseDown = false;
      }
    });

    let touchUpEvent = window.addEventListener('touchend', (e) => {
      if (this.mouseDown) {
        window.removeEventListener('touchmove', touchMoveEvent);
        window.removeEventListener('touchend', touchUpEvent);
        this.mouseDown = false;
      }
    });

    if (this.sliderBar) {
      this.mouseDownEvent = this.sliderBar.addEventListener(
        'mousedown',
        (e) => {
          this.mouseDown = true;
          this.handleSlider(e);
        }
      );

      this.touchDownEvent = this.sliderBar.addEventListener(
        'touchstart',
        (e) => {
          this.mouseDown = true;
          this.handleSlider(e);
        }
      );
    }
  }

  componentWillUnmount() {
    this.sliderBar.removeEventListener('mousedown', this.mouseDownEvent);
    this.sliderBar.removeEventListener('touchstart', this.touchDownEvent);
  }

  registerElement(el) {
    this.sliderBar = el;
  }

  handleSlider(e) {
    e.preventDefault();
    e.stopPropagation();

    if (this.sliderBar) {
      let sliderBarWidth = this.sliderBar.clientWidth;
      let sliderBarLeftPos = this.sliderBar.getBoundingClientRect().left;
      let clickXPos = (e.touches && e.touches[0].pageX) || e.pageX;

      let percentClicked = (clickXPos - sliderBarLeftPos) / sliderBarWidth;

      if (this.props.ticks && this.props.ticks.length > 0) {
        let maxDistance = 1;
        let closestPoint = 0;
        for (let i = 0; i < this.props.ticks.length; i++) {
          var distanceFromTick = Math.abs(
            this.props.ticks[i].value - percentClicked
          );
          if (distanceFromTick < maxDistance) {
            maxDistance = distanceFromTick;
            closestPoint = this.props.ticks[i].value;
          }
        }

        percentClicked = closestPoint;
      }

      percentClicked = Math.max(0, Math.min(1, percentClicked));
      let finalValue = this.getValueFromPercent(percentClicked, true);

      this.setState({
        value: percentClicked,
        textValue: finalValue,
      });

      this.props.onChange && this.props.onChange(finalValue);
    }
  }

  getPercentFromValue(val) {
    let { minVal, maxVal } = this.props;

    //return (finalValue - minVal) / (maxVal - minVal); // linear mode

    // logarythmic:
    let k = Math.log(maxVal + 1) - Math.log(minVal + 1);
    return (Math.log(val + 1) - Math.log(minVal + 1)) / k;
  }

  getValueFromPercent(pos, roundValues = false) {
    let { minVal, maxVal } = this.props;

    //return Math.round(minVal + percent * (maxVal - minVal)); // linear mode

    // logarythmic:
    let k = Math.log(maxVal + 1) - Math.log(minVal + 1);
    let value = Math.exp(Math.log(minVal + 1) + k * pos) - 1;

    if (roundValues) {
      // with round values:
      if (value > 2000) return Math.round(value / 100) * 100;
      if (value > 1000) return Math.round(value / 50) * 50;
      if (value > 200) return Math.round(value / 10) * 10;
      if (value > 100) return Math.round(value / 5) * 5;
    }
    return Math.round(value);
  }

  onTypeValue = (textValue) => {
    this.setState({ textValue });

    let { minVal, maxVal } = this.props;
    let numValue = ~~textValue;

    if (
      /\s*\d+\s*$/.test(textValue) &&
      numValue >= minVal &&
      numValue <= maxVal
    ) {
      this.setState({ value: this.getPercentFromValue(numValue) });
      this.props.onChange && this.props.onChange(numValue);
    }
  };

  onBlurTextValue = () => {
    this.setState({
      textValue: this.getValueFromPercent(this.state.value),
    });
  };

  render() {
    let { label, unit, ticks, valueLabel } = this.props;
    // this is actually percent and not value:
    let { value, textValue } = this.state;

    let cursorStyle = {
      left: Math.max(0, value * 100) + '%',
      transform: 'translateX(calc(-1.4em * ' + value + '))',
    };

    let selectedValueBarStyle = {
      width: Math.round(value * 100) + '%',
    };

    return (
      <div className="flexWrapper flexWrapper--column form-element ValueSlider-wrapper">
        <div className="ValueSlider">
          <div>{label}</div>

          {ticks && ticks.length > 0 && (
            <div className="ValueSlider-ticks-wrapper">
              {ticks.map((tick, i) => (
                <div
                  className="ValueSlider-tick-item"
                  style={{ left: Math.floor(tick.value * 100) + '%' }}
                  key={i}
                >
                  {tick.label}
                </div>
              ))}
            </div>
          )}

          <div
            className="ValueSlider-inner"
            ref={(el) => this.registerElement(el)}
          >
            <div className="ValueSlider-inner-cursor" style={cursorStyle} />
            <div className="ValueSlider-inner-bar" />
            <div
              className="ValueSlider-inner-selected-value-bar"
              style={selectedValueBarStyle}
            />
          </div>

          <div className="ValueSlider-inner-result">
            {valueLabel}
            <input
              className="ValueSlider-text-input"
              type="number"
              value={_.isNil(textValue) ? '' : textValue}
              onChange={(e) => this.onTypeValue(e.target.value)}
              onBlur={this.onBlurTextValue}
            />
            {unit}
          </div>
        </div>
      </div>
    );
  }
}

export default ValueSlider;
