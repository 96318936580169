// @flow
import * as React from 'react';
import classnames from 'classnames';

type StepType = {|
  id: string,
  label: string,
  canTransition?: boolean,
|};

type Props = {|
  currentStep: number,
  fullWidth: boolean,
  steps: Array<StepType>,
  onChange?: (newStep: number) => void,
|};

export default function Stepper({
  currentStep,
  steps,
  fullWidth: fullwidth,
  onChange,
}: Props): React.Node {
  return (
    <div
      className={classnames('stepper', {
        fullwidth,
      })}
    >
      <>
        {steps.map(({ id, label, canTransition }, stepIndex) => (
          <Step
            key={id}
            index={stepIndex}
            name={label}
            canTransition={!!canTransition}
            isCurrent={stepIndex === currentStep}
            isPassed={currentStep > stepIndex}
            onChange={onChange}
          />
        ))}
      </>
    </div>
  );
}

type StepProps = {|
  index: number,
  name: string,
  isCurrent: boolean,
  isPassed: boolean,
  canTransition: boolean,
  onChange?: (newStep: number) => void,
|};
function Step({
  index,
  name,
  isCurrent,
  isPassed,
  canTransition,
  onChange,
}: StepProps): React.Node {
  const clickable = !!onChange && !!canTransition;

  return (
    <div
      className={classnames('step', {
        current: isCurrent,
        passed: isPassed,
        clickable: clickable,
      })}
      onClick={() =>
        !!onChange && !!canTransition ? onChange(index) : undefined
      }
    >
      <div className="step-indicator">
        <span>{index + 1}</span>
      </div>
      <div className="step-label">
        <p>{name}</p>
      </div>
    </div>
  );
}
