import React from 'react';
import classNames from 'classnames';

import config from '@/utils/config';
import { useSite, useSiteOptions } from '@/hooks';
import { getFeatureFlags, featureEnabled } from '@/helpers/models/instance';
import { ImageContext, APIContext } from '@/globals/contexts';

import NextImage from 'next/image';

const { STATIC_ROOT_URL } = config;

// Cloudflare documentation recommends to set a value between 50 and 90 but we may set an even lower value to reduce the size without a significant visual impact on the image
// https://developers.cloudflare.com/images/image-resizing/url-format
const DEFAULT_QUALITY = 50;

export const cloudflareLoader = ({ srcPath, width, quality }) => {
  const params = [
    `width=${width}`,
    `quality=${quality ? quality : DEFAULT_QUALITY}`,
    `format=auto`, // allows the use of webp and avif formats
    `fit=scale-down`, // Image will be shrunk in size to fully fit within the given width or height, but will not be enlarged.
  ].join(',');

  return `${STATIC_ROOT_URL}/cdn-cgi/image/${params}/${srcPath}`;
};

export default function Image({
  src: srcPath,
  alt,
  width,
  height,
  priority,
  additionalClassName,
  ratioClassName,
  defaultNextImage,
  style,
}) {
  const { getImageUrl } = React.useContext(APIContext);
  const site = useSite();
  const cloudflareOptimizationEnabled = featureEnabled(
    'cloudflareImageOptimization',
    getFeatureFlags(site)
  );
  const { siteTitle, 'image-16-9-placeholder': placeholderImage } =
    useSiteOptions();
  const { loadImagesSync } = React.useContext(ImageContext);

  if (!srcPath && !placeholderImage)
    return (
      <div className="thumb-placeholder">
        <span>{siteTitle}</span>
      </div>
    );

  const src = getImageUrl(srcPath || placeholderImage);

  return (
    <div
      className={classNames('NextImage', additionalClassName, ratioClassName, {
        'with-fixed-width': width,
        'default-next-image': defaultNextImage,
      })}
      style={style}
    >
      <NextImage
        loader={cloudflareOptimizationEnabled ? cloudflareLoader : undefined}
        src={src || placeholderImage}
        alt={alt}
        layout={width ? 'intrinsic' : 'fill'}
        objectFit={!width ? 'contain' : undefined}
        height={height}
        width={width}
        priority={priority ?? loadImagesSync}
      />
    </div>
  );
}
