// @flow
// File upload based on https://github.com/SoAanyip/React-FileUpload

import _ from 'lodash';
import * as React from 'react';
import Dropzone from 'react-dropzone';
import classnames from 'classnames';
import request from 'superagent';

import type { File, FileType } from '@/types/models';

import { getApiUrl } from '@/utils/config';

import { ProgressBar } from '@/components/old';

type Error = any;

type Props = {|
  value: ?File,
  error: ?Error,
  onError: (Error) => any,
  disabled?: boolean,
  onChange: ({ file: File, url: string }) => Promise<any>,
  accept?: Array<FileType>,
  path?: string,
|};

type State = {|
  uploading: boolean,
  progress: number,
|};

class InputFileUpload extends React.Component<Props, State> {
  state: State = {
    uploading: false,
    progress: 0,
  };

  // $FlowIgnore
  uploadFile = async (file: File): Promise<any> => {
    return new Promise((resolve, reject) => {
      const { path } = this.props;

      const req = request.post(path).withCredentials();
      req.timeout(3600 * 1000);

      req.on('progress', (e) => this.setState({ progress: e.percent }));
      req.attach('file', file);

      req.end((error, res) => {
        if (error) {
          reject(error);
          return;
        }

        if (!res?.body?.result) {
          reject('File upload error');
          return;
        }

        resolve(`${res.body.result}`);
      });
    });
  };

  onDrop: (Array<File>) => Promise<*> = async (files) => {
    const { onChange, onError } = this.props;
    this.setState({ uploading: true });
    const [file] = files;

    try {
      const uploadUrl = await this.uploadFile(file);
      onChange({ file, url: uploadUrl });
    } catch (error) {
      onError(error);
    } finally {
      this.setState({ progress: 0, uploading: false });
    }
  };

  render(): React.Node {
    const { value: file, error, disabled, accept: acceptProp } = this.props;
    const { uploading, progress } = this.state;

    const className = classnames('InputFileUpload', {
      disabled,
      error,
    });

    const accept = acceptProp || [
      'image/jpeg',
      'image/png',
      'image/gif',
      'application/pdf',
      'application/msword',
    ];

    return (
      <div className={className}>
        <Dropzone onDrop={this.onDrop} multiple={false} accept={accept}>
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} className="input-dropzone-component-inner">
              <input {...getInputProps()} />
              <p>📁 Cliquez ou déposez votre fichier ici</p>

              {uploading && (
                <div className="center-content">Envoi en cours...</div>
              )}

              <div className="dropped-files-list dropped-files-list-to-upload">
                {file && !file.url && (
                  <div
                    className="FormElement-dropped-file-item"
                    key={file.name}
                  >
                    {file.preview && (
                      <div className="FormElement-dropped-file-preview FormElement-dropped-file-preview--uploading">
                        <img src={file.preview} alt="uploaded-file" />
                      </div>
                    )}
                    <span>{file.name || file.path}</span>
                  </div>
                )}
              </div>

              {uploading && progress > 0 && (
                <ProgressBar progress={progress}>{~~progress} %</ProgressBar>
              )}
            </div>
          )}
        </Dropzone>

        <div className="dropped-files-list dropped-files-list-uploaded">
          {file && !!file.url && (
            <div className="FormElement-dropped-file-item" key={file.name}>
              ✅{' '}
              <a
                download
                target="_blank"
                rel="noopener noreferrer"
                href={
                  _.startsWith(file.url, 'data:')
                    ? file.url
                    : getApiUrl() + (file.url || '')
                }
              >
                {file.name || file.path}
              </a>
            </div>
          )}
        </div>

        {error && <div className="error">{error}</div>}
      </div>
    );
  }
}

export default InputFileUpload;
