// @flow
import * as React from 'react';
import classNames from 'classnames';

import type { Style } from '../types';

type Direction = 'row' | 'column';
type Props = {|
  children: React.Node,
  additionalClassName?: string,
  direction?: Direction,
  verticalAlign?: boolean,
  horizontalAlign?: boolean,
  style?: Style,
|};

export default function Flex({
  style,
  children,
  additionalClassName,
  direction = 'row',
  verticalAlign,
  horizontalAlign,
}: Props): React.Node {
  const isRow = direction === 'row';
  const flexStyle: Style = {
    ...style,
    flexDirection: direction,
  };

  if (verticalAlign) {
    flexStyle[isRow ? 'alignItems' : 'justifyContent'] = 'center';
  }

  if (horizontalAlign) {
    flexStyle[isRow ? 'justifyContent' : 'alignItems'] = 'center';
  }

  return (
    <div
      className={classNames('is-flex', additionalClassName)}
      style={flexStyle}
    >
      {children}
    </div>
  );
}

Flex.defaultProps = {
  direction: 'row',
};
