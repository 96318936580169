// @flow
import * as React from 'react';
import classnames from 'classnames';

import type { Style, BulmaSize } from '../types';

type Item = {|
  id: any,
  label: string | React.Node,
  active?: boolean,
  to?: string,
  disabled?: boolean,
  onClick?: () => any,
|};

type Align = 'left' | 'centered' | 'right';

type Props = {|
  items: Array<Item>,
  align?: Align,
  size?: BulmaSize,
  isBoxed?: boolean,
  isToggle?: boolean,
  fullWidth?: boolean,
  additionalClassName?: string,
  style?: Style,
|};

export default function Tabs({
  items,
  align,
  size,
  isBoxed,
  isToggle,
  fullWidth,
  additionalClassName,
  style,
}: Props): React.Node {
  const sizeClassName = !!size ? `is-${size}` : '';
  const alignClassName = !!align ? `is-${align}` : '';
  const className = classnames(
    'tabs',
    sizeClassName,
    alignClassName,
    additionalClassName,
    {
      'is-boxed': !!isBoxed,
      'is-toggle': !!isToggle,
      'is-fullwidth': !!fullWidth,
    }
  );

  return (
    <div className={className} style={style}>
      <ul style={{ padding: 0 }}>
        {items.map(({ id, label, active, to, onClick, disabled }) => {
          return (
            <li
              className={classnames(id ? `tab-item-${id}` : '', {
                'is-active': active,
                'is-disabled': disabled,
              })}
              key={id}
            >
              <a
                onClick={() => {
                  if (disabled || !onClick) return;
                  onClick();
                }}
                disabled={disabled}
              >
                {label}
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
