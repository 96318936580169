// @flow
import * as React from 'react';
import classnames from 'classnames';

import type { Style, SwitchColor, SwitchSize } from '../types';

import { bulmaColorClassName, bulmaSizeClassName } from '@/helpers/style';

import { Field, Label } from './';

export type Props = {|
  value: boolean,
  onChange: (boolean) => any,
  disabled?: boolean,
  id?: string,
  label: string,
  required?: boolean,

  isThin?: boolean,
  isRounded?: boolean,
  isOutlined?: boolean,

  color?: SwitchColor,
  size?: SwitchSize,

  additionalClassName?: string,
  style?: Style,
|};

function Switch({
  value,
  onChange,
  label,
  disabled,
  id,
  size,
  color,
  required,
  additionalClassName,
  style,
  isThin,
  isRounded,
  isOutlined,
}: Props): React.Node {
  const className = classnames(
    'switch',
    bulmaColorClassName(color),
    bulmaSizeClassName(size),
    additionalClassName,
    {
      'is-thin': isThin,
      'is-rounded': isRounded,
      'is-outlined': isOutlined,
    }
  );

  return (
    <Field
      style={style}
      additionalClassName="switch-container"
      onClick={() => onChange(!value)}
    >
      <input
        type="checkbox"
        className={className}
        value={!!value}
        checked={!!value}
        id={id}
        disabled={disabled}
      />

      <Label required={required}>{label}</Label>
    </Field>
  );
}

Switch.defaultProps = {
  isRounded: true,
  size: 'normal',
};

export default Switch;
