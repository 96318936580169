// @flow
// Comes from https://wikiki.github.io/form/checkradio/
import * as React from 'react';

import type {
  CheckboxColor,
  CheckboxType,
  CheckboxSize,
  CheckboxDirection,
} from '../types';

import classNames from 'classnames';
import { bulmaColorClassName, bulmaSizeClassName } from '@/helpers/style';

export type Props = {|
  isChecked: boolean,
  onChange?: (boolean) => any,
  disabled?: boolean,
  id?: string,

  color?: CheckboxColor,
  size?: CheckboxSize,
  type?: CheckboxType,
  direction?: CheckboxDirection,

  additionalClassName?: string,
  style?: {},
|};

function Checkbox({
  isChecked,
  onChange,
  disabled,
  id,
  size,
  color,
  additionalClassName,
  style,
  direction,
}: Props): React.Node {
  const className = classNames(
    'is-checkradio',
    bulmaColorClassName(color),
    bulmaSizeClassName(size),
    additionalClassName,
    {
      'is-rtl': direction === 'right-to-left',
    }
  );

  return (
    <input
      type="checkbox"
      className={className}
      checked={isChecked ? 'checked' : ''}
      id={id}
      disabled={disabled}
      onChange={onChange}
    />
  );
}

Checkbox.defaultProps = {
  type: 'checkbox',
  size: 'normal',
  direction: 'left-to-right',
};

export default Checkbox;
