// @flow
import * as React from 'react';

import classNames from 'classnames';

import type { Style } from '../../types';

type Props = {|
  isGapLess?: boolean,
  isMultiline?: boolean,
  isMobile?: boolean,
  style?: Style,
  children: React.Node,
  isjustifiedCenterContent?: boolean,
  additionalClassName?: string,
  contentVerticallyAligned?: boolean,
  breakpointViewport?: 'desktop' | 'tablet' | 'mobile',
|};

export default function Columns({
  isGapLess,
  isMultiline,
  isMobile,
  style = {},
  children,
  isjustifiedCenterContent,
  contentVerticallyAligned,
  additionalClassName,
  breakpointViewport,
}: Props): React.Node {
  let className = classNames(
    'columns',
    additionalClassName,
    {
      'is-gapless': isGapLess,
      'is-multiline': isMultiline,
      'is-mobile': isMobile,
    },
    {
      'is-desktop': breakpointViewport === 'desktop',
      'is-mobile': breakpointViewport === 'mobile',
    }
  );

  let mergedStyle = {};
  if (isjustifiedCenterContent)
    mergedStyle = { ...mergedStyle, justifyContent: 'center' };
  if (contentVerticallyAligned)
    mergedStyle = { ...mergedStyle, alignItems: 'center' };
  mergedStyle = { ...mergedStyle, ...style };

  return (
    <div style={mergedStyle} className={className}>
      {children}
    </div>
  );
}
