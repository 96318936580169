import React from 'react';
import { CSSTransition } from 'react-transition-group';

export default class FadeInAndScrollTo extends React.Component {
  constructor(props) {
    super(props);
    this.elRef = React.createRef();
  }

  render() {
    return (
      <CSSTransition
        in={!!this.props.show}
        timeout={300}
        classNames="fade"
        unmountOnExit
        onEnter={() =>
          this.props.noScroll ||
          window.scroll({
            top: this.elRef.current.offsetTop - 200, // TODO
            behavior: 'smooth',
          })
        }
      >
        <div ref={this.elRef}>
          {
            this.props.children || <span /> // children required by CSSTransition
          }
        </div>
      </CSSTransition>
    );
  }
}
